jQuery(document).ready(function($) {
    var $btnTogleMenu = $('.btn-toggle-menu');

    if(!$btnTogleMenu.length) return

    // var $toggleMenu = $('.toggle-menu');
    var $nav = $('.site-nav');

    $btnTogleMenu.on('click', function(e) {

        e.preventDefault();

        $nav.toggleClass('show');
        $btnTogleMenu.toggleClass('active');

        // $toggleMenu.slideToggle();
        // $toggleMenu.addClass('mobile');
    });
});