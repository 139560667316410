jQuery(document).ready(function($) {
    var $processos = $('.page-template-processos .box-gallery .swiper-container');

    if(!$processos.length) return

    var mySwiper = new Swiper ($processos, {
        autoplay: {
            delay: 3000,
        },
        initialSlide: 1,
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        speed: 550,
        pagination: {
            el: '.slide-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            575: {
                initialSlide: 0,
                slidesPerView: 1
            }
        }
    });
});