jQuery(document).ready(function($) {
    var $galeria = $('.box-about .box-gallery .swiper-container');

    if(!$galeria.length) return

    var mySwiper = new Swiper ($galeria, {
        autoplay: {
            delay: 4000,
        },
        spaceBetween: 15,
        speed: 550,
        pagination: {
            el: '.slide-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        },
    });
});