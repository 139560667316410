jQuery(document).ready(function($) {
    var $slideFull = $('.home-slide-full .swiper-container');


    if(!$slideFull.length) return

    // Slideshow
    $slideFull.find('.swiper-slide').each(function() {
        var item = $(this);
        var image = $(item).data('image');

        $(item).css({
            'backgroundImage': "url("+image+")"
        });
    });

    var mySwiper = new Swiper ($slideFull, {
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        navigation: {
            prevEl: $slideFull.parent().find('.slide-navigation.prev'),
            nextEl: $slideFull.parent().find('.slide-navigation.next'),
        },
        pagination: {
            el: '.slide-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        },
    });

    $slideFull.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});