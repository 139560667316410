jQuery(document).ready(function($) {
    var $slide = $('.home-slide .swiper-container');

    if(!$slide.length) return

    var mySwiper = new Swiper ($slide, {
        autoplay: {
            delay: 7000,
        },
        parallax: true,
        speed: 550,
        navigation: {
            prevEl: $slide.parent().find('.slide-navigation.prev'),
            nextEl: $slide.parent().find('.slide-navigation.next'),
        },
        pagination: {
            el: '.slide-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        },
    });

    $slide.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});